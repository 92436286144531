import * as React from "react";
// 
const containerSmallStyle = {
    margin: '0px auto',
    maxWidth: '1200px'
};

const Container = ({children}) => {
  return (
    <div style={containerSmallStyle}>
      {children}
    </div>
  );
};

export default Container;
