import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/layout/container";
import { SimpleGrid } from '@mantine/core';
import { Heading } from "../components/text/heading";
import { Lead } from "../components/text/lead";
import './card.css'

import laminate from '../images/services/laminate.jpg'
import vinyl from '../images/services/vinyl-plank.jpg'
import hardwood from '../images/services/hardwood.jpg'

const servicesIntro = {
  backgroundImage: `linear-gradient(45deg, #3f51b5 0%, rgb(44, 55, 120) 100%)`,
  color: "#fff",
};

const container = {
  width: "100%",
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  padding: "1.5rem !important",
  textAlign: "center",
};

const sectionStyle = {
  display: "flex",
  margin: "0 auto",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "0.5rem 0.5rem 0 0",
  background: "#fff",
  overflow: "hidden",
};


const services = [
  {
    id: "43",
    descriptor: "Laminate",
    description:
      "This product is an affordable alternative to hardwood. Laminate is a multi-layer synthetic flooring product fused together with a lamination process. Laminate flooring simulates wood (or sometimes stone) with a photographic applique layer under a clear protective layer.",
    image: laminate,
  },
  {
    id: "44",
    descriptor: "Luxury Vinyl Plank",
    description:
      "This popular product looks like wood but is actually an engineered laminate product. One benefit of vinyl plank flooring is that it is resilient, water resistant, and an affordable alternative to hardwood. If you have children or pets, you might consider using this product for your flooring.",
    image: vinyl,
    },
  {
    id: "45",
    descriptor: "Hardwood",
    description:
      "If you want warmth and charm, nothing surpasses the beauty of hardwood flooring. Mahogany, teak, and cherry add elegance and sophistication to any room whereas oak, maple, and hickory give a more traditional look. You can also look at other green options such as cork or bamboo.",
      image: hardwood,
    },
];

const guarantees = [
  {
    id: "23",
    title: "We Provide Excellent Customer Service",
    description:
      "We are a full-service flooring installation company offering excellent customer service and quality workmanship. From the initial consultation to the completion of the project, we provide you with exceptional service and attention to detail. We pride ourselves in achieving perfection in our installations ensuring customer satisfaction. We promise to meticulously handle every aspect of your flooring project.",
  },
  {
    id: "24",
    title: "Our Customers Come First",
    description:
      "As the owner-operator of Affordable Flooring, my number one priority is customer satisfaction and exceptional workmanship.",
  },
  {
    id: "25",
    title: "We Promise You the Best",
    description:
      "From our initial consultation to the completion of the project, we provide outstanding service and attention to detail that many of our competitors lack. We are a small business that takes pride in our work.",
  },
];

const headingMedium = {
  fontWeight: "300",
  color: "#3f51b5",
  fontFamily: "Quicksand,Helvetica,Arial,sans-serif",
  marginBottom: "1.2rem",
  marginTop: "1.2rem",
  fontSize: "1.75rem",
  textAlign: "left",
};

const headingMediumWhite = {
  color: "white",
};

const paragraphStyle = {
  fontSize: "18px",
  lineHeight: "1.6em",
  marginBottom: "0.8em",
  WebkitFontSmoothing: "antialiased",
  fontFamily: "Open Sans,Helvetica Neue,Arial,Helvetica,Verdana,sans-serif",
};

const columnStyle = {
  padding: '4em',
  maxWidth: '1200px',
  margin: '0 auto'
};

function ServiceItem({ descriptor, description, img }) {
  return (
    <SimpleGrid gap={4} breakpoints={[
      { minWidth: 'sm', cols: 1 },
      { minWidth: 'md', cols: 2 },
      { minWidth: 1200, cols: 2 },
    ]}>
      <div style={columnStyle}>
        <div class="text-left mr-auto">
          <h3 style={headingMedium}> {descriptor} </h3>
          <p style={paragraphStyle}>{description}</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <img width="100%" style={{ maxHeight: "700px" }} src={img} />
      </div>
    </SimpleGrid>
  );
}

function GuaranteeItem({ title, description }) {
  return (
        <div class="card col col-12 col-md-6 col-lg-4 text-left">
          <div class="card-body">
            <h3 style={headingMediumWhite}>{title}</h3>
            <p style={paragraphStyle}>{description}</p>
          </div>
        </div>
  );
}

const Services = () => {
  const ServiceItems = services.map((service) => (
    <ServiceItem
      key={service.id}
      descriptor={service.descriptor}
      description={service.description}
      img={service.image}
      alt={service.description}
    />
  ));

  const GuaranteeItems = guarantees.map((guarantee) => (
    <GuaranteeItem
      key={guarantee.id}
      title={guarantee.title}
      description={guarantee.description}
    />
  ));
  return (
    <Layout>
      <section style={servicesIntro}>
        <div style={container}>
          <Heading element="h1">Services</Heading>
          <Lead element="p" color="white">
            We provide high-quality installations of hardwood, laminate, and
            luxury vinyl flooring.
          </Lead>
        </div>
      </section>

      <section style={sectionStyle}>
        <Container>{ServiceItems}</Container>
      </section>

      <section className="feature accent-1">
    <div style={columnStyle}>
      <SimpleGrid gap={4} breakpoints={[
      { minWidth: 'sm', cols: 1 },
      { minWidth: 'md', cols: 2 },
      { minWidth: 1200, cols: 3 },
    ]} className="row mx-auto">
        {GuaranteeItems}
      </SimpleGrid>
    </div>
  </section>
    </Layout>
  );
};

export default Services;
